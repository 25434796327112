.table-container {
  background-color: #0b1830;
  border-radius: 10px;
  padding: 28px 32px;
  color: #b5b9c0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}

.download-button {
  display: inline-flex;
  justify-content: flex-end;
  color: #6d7483;
}

.download-button span:hover {
  cursor: pointer;
  color: #b5b9c0;
}

.table-container table {
  /* table-layout: fixed; */
}

.table-container table thead {
  background-color: #0b1830;
  text-align: center;
}

.table-container table thead tr th {
  border-color: transparent;
}

.table-container table .table-heading {
  color: #b5b9c0;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-container table tbody tr td {
  padding: 16px;
  text-align: center;
}

.table-container table tbody tr td.dark-bg {
  background-color: #17243b;
}

.table-container table tbody tr td.light-bg {
  background-color: #232f44;
}

.table-container-select-field {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  min-width: 145px;
}

.table-container-select-field button {
  width: 100%;
  max-width: 145px;
  display: flex;
  justify-content: space-between;
  border: 0.7px solid #b5b9c0;
  background-color: transparent;
  border-radius: 10px;
}

.table-container-select-field-ul {
  width: 100%;
  max-width: 147px;
  background-color: #232f44;
  overflow: hidden;
}

.table-container-select-field-ul li:hover {
  background-color: #4a566c;
}

.table-container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
