.tabs-heading {
  color: #858b97;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: #797684;
}
.tabs-divider {
  color: #797684;
  height: 2.5px;
  width: 100%;
}
.active-tab {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tabs-heading-secondary {
  color: #b5b9c0;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: balance;
}
.active-tab-secondary {
  color: #23cbd8;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom-width: 2px;
  border-color: #23cbd8;
  text-wrap: balance;
}
@media (max-width: 866px) {
  .tabs-heading {
    font-size: 11px;
  }
  .active-tab {
    font-size: 11px;
  }
  .tabs-heading-secondary {
    font-size: 11px;
    padding-bottom: 8px;
  }
  .active-tab-secondary {
    font-size: 11px;
    padding-bottom: 8px;
  }
}
