.dropdown-btn {
  background-color: #232f44;
  border: none;
  outline: none;
  height: 32.89px;
  border-radius: 4px;
  max-width: 147px;
  width: 100%;
  color: #8b91a8;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dropdown-btn-user {
  background-color: #232f44;
  border: none;
  outline: none;
  height: 46.89px;
  border-radius: 6px;
  max-width: 147px;
  color: #8b91a8;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-pic {
  max-height: 128px;
  max-width: 135px;
  border-radius: 60%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: -0.5px;
}
.divider {
  height: 0.5px;
  background-color: #797684;
}
.diabled-btn {
  background-color: #232f44;
}
.role-tag {
  color: #23cbd8;
  font-size: 9px;
}

.more-dots {
  font-size: 20px;
  color: white;
  margin-left: 0.8rem;
  cursor: pointer;
}

.pro-lable {
  border-radius: 50%;
  border: 1.5px solid rgb(245, 241, 241);
  padding: 2rem;
}

.page-btn-bg {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* padding: 8px 18px; */
  border: 1px solid white;
  /* border-radius: 6px; */
  background: transparent;
}

@media (max-width: 800px) {
  .profile-pic {
    max-height: 143px;
    max-width: 143px;
    border-radius: 60%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: -4.2px;
    left: 3.2px;
  }
}
