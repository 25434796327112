.speedo-meter-container {
  background-color: #0b1830;
  border-radius: 10px;
  padding: 24px 16px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.speedo-merter-heading,
.speedo-merter-points,
.speedo-merter-points-fade {
  color: white;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.speedo-merter-points {
  font-size: 24px;
}

.speedo-merter-desc {
  color: #9da3ac;
  font-size: 11px;
}

.speedo-meter-container svg {
  width: 100%;
  height: 100%;
}

.speedo-merter-desc-container {
  text-align: center;
}
