.lines-main-container {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.lines-main-container.recenter {
  transform: translateX(-15%);
}

.lines-main-container.small {
  max-width: 80px;
  gap: 16px;
}

.group-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.lines-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 70%;
  margin-left: calc(30% + 10px);
}

.lines-main-container.small .lines-container {
  width: 80%;
  margin-left: calc(20% + 10px);
}

.pointer-container {
  color: #e8e8ea;
  display: flex;
  gap: 2px;
  height: 30px;
  width: 30%;
  position: absolute;
  line-height: 1;
  white-space: pre;
  transform: translateY(-50%);
}

.lines-main-container.small .pointer-container {
  width: 20%;
}

.lines-container-heading {
  color: white;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: calc(30% + 10px);
  white-space: pre;
}

.lines-main-container.small .lines-container-heading {
  margin-left: calc(5% + 10px);
  font-size: 16px;
}

.lines-container-single {
  width: 100%;
  height: 6px;
  border-radius: 100px;
  background-color: aqua;
}

.lines-main-container.small .lines-container-single {
  height: 3px;
}

.lines-container-pointer {
  width: 17px;
  height: 17px;
  background: white;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.percentage-value {
  color: #23cbd8;
  font-size: 24px;
  font-family: "Lexend Deca";
  font-weight: bolder;
  margin-left: calc(30% + 10px);
}

.lines-main-container.small .percentage-value {
  margin-left: calc(20% + 10px);
  font-size: 20px;
}
