.right-login-wrapper {
  background-color: #23cbd8;
}

.login-form {
  width: 100%;
  max-width: 348px;
  margin-left: 70px;
}
.login-heading {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2rem;
}

.checkbox-lable {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .mob-banner {
    height: 100px !important;
  }
}
@media (max-width: 1024px) {
  .mob-banner {
    height: 70px !important;
  }
}

@media (max-width: 767px) {
  .login-form {
    width: 100%;
    max-width: 348px;
    margin: auto;
  }

  .login-heading {
    color: #fff;
    font-family: "Lexend Deca";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2rem;
  }

  .checkbox-lable {
    font-size: 12px;
  }
  .mob-banner {
    height: 94px !important;
  }
}
.error-msg {
  font-size: 14px;
  color: rgb(215, 34, 34);
  margin-top: -0.8rem;
  margin-bottom: 0.5rem;
}
