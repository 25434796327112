.quality-page {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.quality-page-title {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
