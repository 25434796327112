.report-flow-tag {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 30px;
}

.report-flow-tag.border-bottom {
  border-bottom: 0.25px solid rgba(255, 255, 255, 0.5);
}

.report-flow-tag-title,
.report-flow-tag-unit {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
}
.report-flow-tag-unit {
  opacity: 0.5;
}

.report-flow-tag-container {
  padding: 8px 16px;
  border: 0.25px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.txtsm {
  padding: 8px 12px;
  font-size: 16px;
  text-wrap: nowrap;
}
