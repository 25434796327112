.pagination-container {
  display: flex;
  align-items: center;
  gap: 36px;
}
@media (max-width: 467px) {
  .pagination-container {
    gap: 8px;
  }
}

.pagination-container svg {
  fill: white;
  width: 9px;
  height: 16px;
  cursor: pointer;
}

.page-number-container {
  display: flex;
  gap: 8px;
  color: white;
  font-size: 15px;
}

.pagination-container svg.disabled {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}
