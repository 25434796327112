.flow-page {
  color: white;
}

.flow-page-stats {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
}
.flow-page-stats-flow {
  height: 350px;
}
