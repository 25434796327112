.nav-wrapper {
  width: calc(100% - 250px);
  position: fixed;
  top: 0;
  margin-left: 250px;
  z-index: 1;
}

.sidebar-wrapper {
  position: fixed;
  left: 0;
}

.active-link-nav {
  color: #fff !important;
}
.notifications-box {
  display: none;
  border: 1px solid #bcbac1;
  width: 39px;
  height: 39px;
  border-radius: 2px;
  /* display: flex; */
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.not-count {
  position: absolute;
  top: -11px;
  right: -8px;
  background-color: #ff4343;
  color: #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.info {
  color: #797684;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 4px;
}
.name {
  color: #bcbac1;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.arrow-back {
  position: absolute;
  left: -30px;
  z-index: 1;
  cursor: pointer;
}

.links-color {
  color: #bcbac1 !important;
}

.mobile-nav-header {
  padding-top: 88px;
  background-color: #111e36;
}
@media (min-width: 1100px) {
  .search-input {
    width: 354px;
  }
}
.info-link {
  font-size: 16px;
}
