.monitor-indicator-container {
  height: 52px;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  color: white;
}

.monitor-indicator-container.not-full-width {
  max-width: 200px;
}

@media (max-width: 455px) {
  .monitor-indicator-container.not-full-width {
    max-width: 100%;
  }
}

.monitor-indicator-container.primary {
  border: 1.75px solid #23cbd8;
}
.monitor-indicator-container.secondary {
  border: 1.75px solid #c2e823;
}
.monitor-indicator-container.error {
  border: 1.75px solid #fc2965;
}
.monitor-indicator-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.monitor-indicator-circle.primary {
  background-color: #23cbd8;
}

.monitor-indicator-circle.secondary {
  background-color: #c2e823;
}

.monitor-indicator-circle.error {
  background-color: #fc2965;
}

.monitor-indicator-text {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}

.filter-rows {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}
