.progress-bar-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.progress-bar-heading {
  color: white;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 16px;
  background-color: transparent;
  border-radius: 10px;
  max-width: 300px;
  overflow: hidden;
  display: flex;
  gap: 4px;
}

@media (max-width: 1200px) {
  .progress-bar {
    gap: 3px;
  }
}

.progress-bar-with-pointer {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.progress-bar-pointer {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 222;
  width: 18px;
  height: 18px;
  background: white;
  clip-path: polygon(50% 100%, 100% 0, 0 0);
}
