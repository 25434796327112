.sensor-tag-container {
  width: 100%;
  padding: 6px 5px 8px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
}
.sensor-tag-container-lg {
  width: 100%;
  padding: 18px 5px 18px 12px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
}

.sensor-tag-container svg {
  width: 15%;
  height: 18px;
}
.sensor-tag-container-lg svg {
  width: 15%;
}

.sensor-tag-container.primary {
  border: 1.75px solid #23cbd8;
  color: #23cbd8;
}
.sensor-tag-container.secondary {
  border: 1.75px solid white;
  color: white;
  opacity: 0.5;
}

.sensor-tag-container.secondary svg {
  color: white;
  opacity: 0.5;
}

.sensor-tag-text {
  color: #23cbd8;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80%;
}

.sensor-tag-container.secondary .sensor-tag-text {
  color: white;
}

.sensor-tag-container-lg.primary {
  border: 1.75px solid #23cbd8;
  color: #23cbd8;
}
.sensor-tag-container-lg.secondary {
  border: 1.75px solid white;
  color: white;
  opacity: 0.5;
}

.sensor-tag-container-lg.secondary svg {
  color: white;
  opacity: 0.5;
}

.sensor-tag-text {
  color: #23cbd8;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}

.sensor-tag-container-lg.secondary .sensor-tag-text {
  color: white;
}
