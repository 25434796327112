.monitor-page {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.monitor-row {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.monitor-title {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.facilities-single-container {
  background-color: #0b1830;
  border-radius: 12px;
  padding: 36px 28px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.more-info-text {
  color: #b5b9c0;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.facilities-filter {
  display: flex;
  justify-content: space-around;
  gap: 50px;
}

.filter-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.pro-per-day {
  color: white;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
