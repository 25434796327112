.status-tag-container {
  padding: 16px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  color: white;
}

.status-tag-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.status-tag-circle.primary {
  background-color: #419241;
}
.status-tag-circle.tertiary {
  background-color: #23cbd8;
}

.status-tag-circle.secondary {
  background-color: #c2e823;
}

.status-tag-circle.error {
  background-color: #fc2965;
}

.status-tag-circle.disabled {
  background-color: white;
  opacity: 0.5;
}

.status-tag-text {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
}
