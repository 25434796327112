.primary-font {
  color: #858b97;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.secondary-font {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sub-heading {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bg-1 {
  background-color: #0b1830;
}
.bg-2 {
  background-color: #18243b;
}

input::placeholder {
  color: #8f8c98;
  font-family: "Lexend Deca";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.primary-btn {
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10.5px 20px;
  border: 2px solid white;
  border-radius: 6px;
}
.secondary-btn {
  background-color: #23cbd8;
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  text-wrap: nowrap;
}

.tertiary-btn {
  color: #fff;
  font-family: "Lexend Deca";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 18px;
  border: 1px solid white;
  border-radius: 6px;
}
.tertiary-btn:hover {
  background: rgb(55 65 81) !important;
}
.primary-input {
  background-color: #232f44;
  border: none;
  outline: none;
  height: 47.89px;
  border-radius: 6px;
  font-size: 16px;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.primary-input-label {
  color: #858b97;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
  padding-left: 8px;
}

.secondary-input {
  background-color: #ebffff;
  border: none;
  outline: none;
  height: 47.89px;
  border-radius: 6px;
  font-size: 16px;
  color: rgb(23, 22, 22);
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.secondary-input-label {
  color: #ebffff;
  font-family: "Lexend Deca";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 8px;
  padding-left: 1px;
}

.main-content-wrapper {
  /* margin-left: 250px; */
  padding-top: 103px;
  padding-left: 300px;
  padding-right: 50px;
  /* padding-top: 35px; */
}
.main-layout-wrapper {
  background-color: #18243b;
}
.cancel-btn {
  color: #fff;
  text-align: center;
  font-family: "Lexend Deca";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8.5px 16px;
  border: 1px solid white;
  border-radius: 6px;
  cursor: pointer;
}
.disable-input {
  cursor: not-allowed;
  color: #858b97;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid #434346;
  -webkit-text-fill-color: #f1f1f1;
  -webkit-box-shadow: 0 0 0px 1000px #232f44 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.chart-container {
  padding: 0;
}
.months-bar {
  color: #6d7483;
  font-family: "Lexend Deca";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dropdown-bar-mob {
  display: flex;
}
.dropdown-bar-mob-view {
  display: none !important;
}

.graph-skeleton > div {
  height: 600px;
}

.graph-skeleton > div:nth-child(odd) {
  height: 450px;
}
@media (max-width: 866px) {
  .main-content-wrapper {
    padding-top: 83px;
    padding-left: 265px;
    padding-right: 18px;
  }
  .dropdown-bar-mob {
    display: none !important;
  }
  .dropdown-bar-mob-view {
    display: flex !important;
  }
  .graph-skeleton > div {
    height: 100px;
    margin-inline-start: 0.5rem;
  }

  .graph-skeleton > div:nth-child(odd) {
    height: 50px;
    margin-inline-start: 0.5rem;
  }
}
@media (max-width: 766px) {
  .primary-input-label {
    font-size: 12px;
  }
  .secondary-input-label {
    font-size: 12px;
  }
  .main-content-wrapper {
    padding: 1rem;
    padding-top: 30px;
  }
}
@media (max-width: 1200px) {
  .graph-skeleton > div {
    height: 200px;
    margin-inline-start: 1rem;
  }

  .graph-skeleton > div:nth-child(odd) {
    height: 100px;
    margin-inline-start: 1rem;
  }
}
@media (max-width: 1600px) {
  .graph-skeleton > div {
    height: 175px;
    margin-inline-start: 1rem;
  }

  .graph-skeleton > div:nth-child(odd) {
    height: 150px;
    margin-inline-start: 1rem;
  }
}
@media (max-width: 1800px) {
  .graph-skeleton > div {
    height: 325px;
    margin-inline-start: 0.5rem;
  }

  .graph-skeleton > div:nth-child(odd) {
    height: 140px;
    margin-inline-start: 0.5rem;
  }
}
@media (max-width: 2200px) {
  .graph-skeleton > div {
    height: 275px;
    margin-inline-start: 0.5rem;
  }

  .graph-skeleton > div:nth-child(odd) {
    height: 200px;
    margin-inline-start: 0.5rem;
  }
}
.acccent-switch {
  background-color: #23cbd8;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
